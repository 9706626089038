"use strict";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "pro",
  class: "w-full min-h-screen bg-gray-1000 h-full"
};
export function render(_ctx, _cache) {
  const _component_NuxtPage = _resolveComponent("NuxtPage");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NuxtPage)
  ]);
}
